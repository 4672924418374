
































import { Component, Prop } from 'nuxt-property-decorator';
import Vue from 'vue';
import { BlokContainerSection } from '~/types';

@Component
export default class ContainerSection extends Vue {
  @Prop() blok!: BlokContainerSection;
}
